var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{staticClass:"type-message-modal",attrs:{"centered":"","width":"80%","maskClosable":false,"closable":false,"footer":null,"afterClose":_vm.atClose},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"type-message-wrapper"},[_c('div',{staticClass:"header-wrapper"},[_vm._v(" "+_vm._s(_vm.type ? _vm.lbl['type-message-modal-header-title'] + ' ' + _vm.type : _vm.lbl['type-message-modal-header-title'])+" ")]),_c('div',{staticClass:"select-type-message-wrapper"},[_c('div',{staticClass:"type-message",class:{
            'type-message-disable': _vm.targetCount > _vm.lineLimit,
            selected: _vm.type == 'Line',
          },on:{"click":function($event){_vm.targetCount > _vm.lineLimit ? function () {} : _vm.selectType('Line')}}},[_c('div',{staticClass:"type-message-detail"},[_c('img',{staticClass:"line-logo",attrs:{"src":require("@/assets/images/logo_line.svg"),"alt":"Line"}}),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-line-title'])+" ")]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-line-desc'])+" ")])]),_c('div',{staticClass:"type-message-quata-line",class:{
              'type-message-quata-disable': _vm.targetCount > _vm.lineLimit,
            }},[_c('div',{staticClass:"noted"},[_c('span',[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-quota-line-recheck-text'])+" ")]),_c('a',{staticClass:"link",class:{
                  'a-selected': _vm.type == 'Line',
                },attrs:{"href":_vm.lbl['type-message-modal-quota-line-recheck-url-text'],"target":"_blank","a-selected":""}},[_vm._v(" "+_vm._s(_vm.lbl['click-here-label'])+" ")])])])]),_c('div',{staticClass:"type-message",class:{
            'type-message-disable': _vm.isUnlimitSMS
              ? false
              : _vm.targetCount > _vm.smsLimit,
            selected: _vm.type == 'SMS',
          },on:{"click":function($event){_vm.targetCount > _vm.smsLimit && !_vm.isUnlimitSMS
              ? function () {}
              : _vm.selectType('SMS')}}},[_c('div',{staticClass:"type-message-detail"},[(_vm.isUnlimitSMS ? true : _vm.targetCount <= _vm.smsLimit)?_c('div',{staticClass:"circle"},[_c('img',{attrs:{"src":require("@/assets/images/logo_sms.svg"),"alt":"SMS"}})]):_c('div',{staticClass:"circle-disable"},[_c('img',{attrs:{"src":require("@/assets/images/logo_sms_disable.svg"),"alt":"SMS Disable"}})]),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.lbl['type-message-modal-sms-title']))]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.lbl['type-message-modal-sms-desc']))])]),_c('div',{staticClass:"type-message-quata",class:{
              'type-message-quata-disable': _vm.isUnlimitSMS
                ? false
                : _vm.targetCount > _vm.smsLimit,
            }},[(_vm.isUnlimitSMS ? false : _vm.targetCount > _vm.smsLimit)?_c('div',{staticClass:"warnning"},[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-warning-insufficient-quota-text'])+" ")]):_vm._e(),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-quota-text'])+" ")]),_c('div',{staticClass:"quata"},[_vm._v(" "+_vm._s(_vm.isUnlimitSMS ? _vm.lbl['header-view-message-unlimited'] : _vm.smsLimit)+" ")])])]),_c('div',{staticClass:"type-message",class:{ selected: _vm.type == 'Email' },on:{"click":function($event){return _vm.selectType('Email')}}},[_c('div',{staticClass:"type-message-detail"},[_c('div',{staticClass:"circle"},[_c('img',{attrs:{"src":require("@/assets/images/logo_email.svg"),"alt":"Email"}})]),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-email-title'])+" ")]),_c('div',{staticClass:"desc"},[_vm._v(_vm._s(_vm.lbl['type-message-modal-email-desc']))])]),_c('div',{staticClass:"type-message-quata"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-quota-text'])+" ")]),_c('div',{staticClass:"quata"},[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-quota-unlimit'])+" ")])])])]),_c('div',{staticClass:"footer-wrapper"},[_c('div',{staticClass:"message"},[_c('span',[_vm._v(_vm._s(_vm.lbl['smarttarget-contact-to-topup']))]),_c('router-link',{attrs:{"id":"contact_us_link","to":{
              name: 'about',
              params: {
                mode: 'contactus',
              },
            }}},[_c('span',{staticClass:"link"},[_vm._v(" "+_vm._s(_vm.lbl['type-message-modal-bzbretail'])+" ")])])],1),_c('div',{staticClass:"button-wrapper"},[_c('a-button',{staticClass:"cancel-btn",attrs:{"size":"large"},on:{"click":function($event){return _vm.atClose()}}},[_vm._v(" "+_vm._s(_vm.lbl['btn_cancel'])+" ")]),_c('a-button',{staticClass:"next-btn",attrs:{"size":"large"},on:{"click":function($event){return _vm.clickNext()}}},[_vm._v(" "+_vm._s(_vm.lbl['btn_next'])+" ")])],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }