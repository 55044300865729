<template>
  <div>
    <a-modal
      v-model="visible"
      centered
      width="80%"
      :maskClosable="false"
      :closable="false"
      :footer="null"
      :afterClose="atClose"
      class="type-message-modal"
    >
      <div class="type-message-wrapper">
        <div class="header-wrapper">
          {{
            type
              ? lbl['type-message-modal-header-title'] + ' ' + type
              : lbl['type-message-modal-header-title']
          }}
        </div>
        <!-- Select -->
        <div class="select-type-message-wrapper">
          <!-- Line -->
          <div
            class="type-message"
            :class="{
              'type-message-disable': targetCount > lineLimit,
              selected: type == 'Line',
            }"
            @click="targetCount > lineLimit ? () => {} : selectType('Line')"
          >
            <div class="type-message-detail">
              <img
                src="@/assets/images/logo_line.svg"
                alt="Line"
                class="line-logo"
              />
              <div class="title">
                {{ lbl['type-message-modal-line-title'] }}
              </div>
              <div class="desc">
                {{ lbl['type-message-modal-line-desc'] }}
              </div>
            </div>
            <div
              class="type-message-quata-line"
              :class="{
                'type-message-quata-disable': targetCount > lineLimit,
              }"
            >
              <div class="noted">
                <span>
                  {{ lbl['type-message-modal-quota-line-recheck-text'] }}
                </span>
                <a
                  :href="lbl['type-message-modal-quota-line-recheck-url-text']"
                  target="_blank"
                  a-selected
                  class="link"
                  :class="{
                    'a-selected': type == 'Line',
                  }"
                >
                  {{ lbl['click-here-label'] }}
                </a>
              </div>
            </div>
          </div>

          <!-- SMS -->
          <div
            class="type-message"
            :class="{
              'type-message-disable': isUnlimitSMS
                ? false
                : targetCount > smsLimit,
              selected: type == 'SMS',
            }"
            @click="
              targetCount > smsLimit && !isUnlimitSMS
                ? () => {}
                : selectType('SMS')
            "
          >
            <div class="type-message-detail">
              <div
                v-if="isUnlimitSMS ? true : targetCount <= smsLimit"
                class="circle"
              >
                <img src="@/assets/images/logo_sms.svg" alt="SMS" />
              </div>
              <div v-else class="circle-disable">
                <img
                  src="@/assets/images/logo_sms_disable.svg"
                  alt="SMS Disable"
                />
              </div>
              <div class="title">{{ lbl['type-message-modal-sms-title'] }}</div>
              <div class="desc">{{ lbl['type-message-modal-sms-desc'] }}</div>
            </div>
            <div
              class="type-message-quata"
              :class="{
                'type-message-quata-disable': isUnlimitSMS
                  ? false
                  : targetCount > smsLimit,
              }"
            >
              <div
                v-if="isUnlimitSMS ? false : targetCount > smsLimit"
                class="warnning"
              >
                {{ lbl['type-message-modal-warning-insufficient-quota-text'] }}
              </div>
              <div class="title">
                {{ lbl['type-message-modal-quota-text'] }}
              </div>
              <div class="quata">
                {{
                  isUnlimitSMS ? lbl['header-view-message-unlimited'] : smsLimit
                }}
              </div>
            </div>
          </div>

          <!-- Email -->
          <div
            class="type-message"
            :class="{ selected: type == 'Email' }"
            @click="selectType('Email')"
          >
            <div class="type-message-detail">
              <div class="circle">
                <img src="@/assets/images/logo_email.svg" alt="Email" />
              </div>
              <div class="title">
                {{ lbl['type-message-modal-email-title'] }}
              </div>
              <div class="desc">{{ lbl['type-message-modal-email-desc'] }}</div>
            </div>
            <div class="type-message-quata">
              <!-- <div class="warnning">{{lbl['type-message-modal-warning-insufficient-quota-text']}}</div> -->
              <div class="title">
                {{ lbl['type-message-modal-quota-text'] }}
              </div>
              <div class="quata">
                {{ lbl['type-message-modal-quota-unlimit'] }}
              </div>
            </div>
          </div>
        </div>
        <!-- End Select -->
        <div class="footer-wrapper">
          <div class="message">
            <span>{{ lbl['smarttarget-contact-to-topup'] }}</span>
            <router-link
              id="contact_us_link"
              :to="{
                name: 'about',
                params: {
                  mode: 'contactus',
                },
              }"
            >
              <span class="link">
                {{ lbl['type-message-modal-bzbretail'] }}
              </span>
            </router-link>
          </div>
          <div class="button-wrapper">
            <a-button size="large" class="cancel-btn" @click="atClose()">
              {{ lbl['btn_cancel'] }}
            </a-button>
            <a-button size="large" class="next-btn" @click="clickNext()">
              {{ lbl['btn_next'] }}
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Mixin from '@/mixin/Mixin'

export default {
  name: 'TypeMessageModal',
  mixins: [Mixin],
  data() {
    return {
      visible: false,
      type: null,
      targetCount: 0,
    }
  },
  props: {
    show: Boolean,
    selectedTarget: Object,
    smsLimit: {
      type: [Number, String],
    },
    lineLimit: {
      type: [Number, String],
    },
    isUnlimitSMS: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show(newVal) {
      this.visible = newVal
    },
    selectedTarget: {
      handler(val) {
        console.log('selectedTarget new val ::', val)
        this.targetCount = parseInt(val.target)
      },
      deep: true,
    },
  },
  methods: {
    selectType(value) {
      this.type = value
    },
    gotoLineBusiness() {
      var url = 'https://manager.line.biz/'
      window.open(url, '_blank')
    },
    atClose() {
      this.$emit('closeModal')
    },
    clickNext() {
      console.log(this.selectedTarget)
      this.visible = false

      this.$store.dispatch('setSelectedSmartTarget', this.selectedTarget)
      sessionStorage.setItem('target', JSON.stringify(this.selectedTarget))
      this.$router.push({
        name: 'InactiveUserV2',
        query: {
          mode: 'create',
          id: this.selectedTarget.name,
          type: this.type,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.type-message-modal {
  .type-message-wrapper {
    padding: 1px 15px;
    .header-wrapper {
      color: #192a3e;
      text-align: left;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.15px;
      margin-bottom: 30px;
    }
    .select-type-message-wrapper {
      display: flex;
      gap: 16px;
      .type-message {
        cursor: pointer;
        flex: 1;
        display: flex;
        flex-direction: column;
        .type-message-detail {
          flex-grow: 1;
          border-radius: 4px 4px 0px 0px;
          padding: 16px;
          text-align: left;
          .line-logo {
            width: 60px;
            height: 60px;
          }
          .circle {
            background-color: #fff6e6;
            border-radius: 60px;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .circle-disable {
            background-color: #d1d1d1;
            border-radius: 60px;
            width: 60px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .title {
            margin-top: 14px;
            color: #4a4a4a;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
          .desc {
            color: #636363;
            line-height: 20px;
            letter-spacing: 0.25px;
          }
        }
        .type-message-quata-line {
          position: relative;
          border-radius: 0px 0px 4px 4px;
          border: 2px solid #fff6e6;
          background: #fff6e6;
          padding: 8px 16px;
          text-align: left;
          color: #f9a601;
          .detail-quata {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
              font-weight: 700;
            }
            .linebusiness {
              text-decoration-line: underline;
              font-style: italic;
            }
            .warnning {
              position: absolute;
              top: -24px;
              right: 16px;
              color: #ff4242;
              font-size: 12px;
            }
          }
          .noted {
            .link {
              color: #f9a601;
            }
          }
        }
        .type-message-quata {
          position: relative;
          border-radius: 0px 0px 4px 4px;
          border: 2px solid #fff6e6;
          background: #fff6e6;
          padding: 8px 16px;
          text-align: left;
          color: #f9a601;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #f9a601;

          .title {
            font-weight: 700;
          }
          .linebusiness {
            text-decoration-line: underline;
            font-style: italic;
          }
          .warnning {
            position: absolute;
            top: -24px;
            right: 16px;
            color: #ff4242;
            font-size: 12px;
          }
        }
        .type-message-quata-disable {
          border-radius: 0px 0px 4px 4px;
          border: 2px solid #d1d1d1;
          background: #d1d1d1;
          color: #b0b0b0;
        }
      }
      //   .type-message:hover {
      //     .type-message-detail {
      //       background: #fff6e6;
      //     }

      //     .type-message-quata {
      //       border: 2px solid #f9a601;
      //       background: #f9a601;
      //       color: #ffffff;
      //     }
      //   }
      .type-message-disable {
        cursor: not-allowed;
      }
      .selected {
        .type-message-detail {
          border: 2px solid #f9a601;
          background: #fff6e6;
        }
        .type-message-quata-line,
        .type-message-quata {
          border: 2px solid #f9a601;
          background: #f9a601;
          color: #ffffff;
        }
      }
      @media only screen and (max-width: 867px) {
        flex-direction: column;
      }
    }
    .footer-wrapper {
      margin-top: 16px;
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .message {
        color: #636363;
        letter-spacing: 0.25px;
        .link {
          color: #40a9ff;
          font-style: italic;
          text-decoration-line: underline;
        }
      }
      .button-wrapper {
        display: flex;
        gap: 16px;
        .cancel-btn {
          width: 100px;
          border-radius: 6px;
          border: 1px solid #f9a601;
          background: #ffffff;
          color: #f9a601;
          font-weight: bold;
        }
        .next-btn {
          width: 100px;
          border-radius: 6px;
          border: 1px solid #f9a601;
          background: #f9a601;
          color: #ffffff;
          font-weight: bold;
        }
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
.a-selected {
  color: #ffffff !important;
}
</style>
